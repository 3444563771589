import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlertService } from './../shared/services/alert.service';
import { AppPath } from './../shared/services/app-path.service';
import { FirstLoadService } from './../shared/services/first-load.service';
import { GlobalEventsService } from './../shared/services/global-events.service';
import { HelperService } from './../shared/services/helper.service';
import { CustomSnackbarComponent } from '../shared/components/custom-snackbar/custom-snackbar.component';
import { CustomSnackbarData } from '../shared/models/snackbar/custom-snackbar-data';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy, OnInit {
    @HostBinding('class')
    className = 'main';

    @HostBinding('id')
    idName = 'main';

    public loading = true;

    private firstLoadSubscription: Subscription | undefined;
    private urlPageSubscription: Subscription | undefined;

    constructor(
        private router: Router,
        private globalEventsService: GlobalEventsService,
        private firstLoadService: FirstLoadService,
        private helperService: HelperService,
        private appPath: AppPath,
        private snackBar: MatSnackBar,
        private alertService: AlertService
    ) {
        this.firstLoadSubscription = this.globalEventsService.firstLoaded.subscribe((loaded: boolean) => {
            if (!loaded) {
                return;
            }

            this.loading = false;
        });

        this.urlPageSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // console.log(event);
                if (event.url) {
                    if (event.url.match(/(^\/recover\/\d+\/)/gm)) {
                        this.firstLoadService.addLoader('recover-email');
                    }
                }
            }

            if (event instanceof NavigationEnd) {
                if (event.url) {
                    const path = event.url;

                    if (this.helperService.checkStaticMetaPages(path)) {
                        const title = this.appPath.getTitle(path);
                        this.helperService.setTitle(title);
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.firstLoadSubscription?.unsubscribe();
        this.urlPageSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.handleSnackBar();
        }, 1000);
    }

    private handleSnackBar() {
        const data: CustomSnackbarData = {
            type: '',
            message: 'test message',
            close: 'close',
            go: 'view',
            callback: () => {}
        };

        this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data,
            duration: 0,
            panelClass: 'custom-snackbar-container'
        });

        this.alertService.error(['danger test.'], true);

        setTimeout(() => {
            this.alertService.warning(['warned test'], true);
        }, 5000);
    }
}
