import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
    MatLegacySnackBarRef as MatSnackBarRef,
    LegacySimpleSnackBar as SimpleSnackBar
} from '@angular/material/legacy-snack-bar';

import { CustomSnackbarData } from '../../models/snackbar/custom-snackbar-data';

@Component({
    selector: 'app-custom-snackbar',
    templateUrl: './custom-snackbar.component.html',
    styleUrls: ['./custom-snackbar.component.scss'],

    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSnackbarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarData,
        private snackBarRef: MatSnackBarRef<SimpleSnackBar>
    ) {}

    public close() {
        this.snackBarRef.dismiss();
    }

    public go() {
        this.snackBarRef.dismiss();
        if (typeof this.data.callback === 'function') {
            this.data.callback();
        }
    }
}
