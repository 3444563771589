import { DefaultAppConfig } from 'src/app/shared/models/config';
import { environment } from './environment';

export const defaultAppConfig: DefaultAppConfig = {
    production: environment.production,
    debug: false,
    title: 'BIT-HDTV',
    browserStorage: {
        authToken: 'authToken',
        darkMode: 'darkMode'
    },
    apiUrl: '/api',
    apis: {
        auth: '/auth',
        misc: '/misc',
        user: '/user',
        torrent: '/torrent'
    },
    gazelleUrl: '/sub',
    versionCheckInterval: 1000 * 60 * 2,
    userStatsInterval: 1000 * 60 * 1,
    currentVersion: '',
    currentHash: '',
};
