import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';

import { DEBUG } from './debug';
import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { APP_CONFIG, AppConfig } from '../models/config';

export const ConfigProviders = [
    {
        provide: DEBUG,
        useFactory: (appConfig: AppConfig) => appConfig.debug,
        deps: [APP_CONFIG]
    },
    {
        provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
        useValue: {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: 'custom-snackbar'
        }
    },
    {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
            panelClass: 'custom-dialog',
            closeOnNavigation: true,
            hasBackdrop: true,
            disableClose: true
        }
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    }
];
