import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APP_CONFIG, AppConfig } from '../models/config';
import { ResponseApi } from '../models/general';
import { RouteInfo } from '../models/routes';
import { SiteSettings } from '../models/setting/site-settings';

@Injectable({
    providedIn: 'root'
})
export class SettingRepository {
    private apiUrl: string;
    private currentVersion = this.appConfig.currentVersion;

    constructor(
        private http: HttpClient,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {
        this.apiUrl = this.appConfig.getApiUrl(this.appConfig.apis.misc);
    }

    getSiteSettings() {
        return this.http.get<ResponseApi<SiteSettings>>(`${this.apiUrl}/setting/site`);
    }

    getAppRoutes() {
        return this.http.get<RouteInfo[]>(`/app-routes.json?v=${this.currentVersion}`);
    }
}
