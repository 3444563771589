import type { Apis, Rewrites } from 'src/app/shared/models/config';

export const rewritesApi: Partial<Apis> = {
    // misc: 'http://localhost:9501',
    // auth: 'http://localhost:9502',
    // user: 'http://localhost:9503'
    // torrent: 'http://localhost:9504'
};

export const rewrites: Partial<Rewrites> = {
    // apiUrl: 'http://bithdtv.test/api',
    // gazelleUrl: "https://bit-hdtv-gazelle.test",
    // versionCheckInterval: 1000 * 10,
    // userStatsInterval: 1000 * 10,
};
