import { InjectionToken } from '@angular/core';

export type BrowserStorage = {
    authToken: string;
    darkMode: string;
};

export type Apis = {
    auth: string;
    misc: string;
    user: string;
    torrent: string;
};

export interface DefaultAppConfig {
    production: boolean;
    debug: boolean;
    title: string;
    browserStorage: BrowserStorage;
    apiUrl: string;
    apis: Apis;
    gazelleUrl: string;
    versionCheckInterval: number;
    userStatsInterval: number;
    currentVersion: string;
    currentHash: string;
}

export type Rewrites = Pick<DefaultAppConfig, 'apiUrl' | 'gazelleUrl' | 'userStatsInterval' | 'versionCheckInterval'>;

export interface AppConfig extends DefaultAppConfig {
    getApiUrl(apiPath: string): string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
