import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { APP_CONFIG, AppConfig } from 'src/app/shared/models/config';
import { VersionCheck } from '../models/version/version-check';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    private versionCheckUrl = '/version.json';
    private currentHash = this.appConfig.currentHash;

    private defaultFrequency = 1000 * 60 * 60 * 30;
    private frequency: number = this.appConfig.versionCheckInterval;

    private firstLoad: boolean = true;

    constructor(
        private http: HttpClient,
        private snackBar: MatSnackBar,
        @Inject(APP_CONFIG) private appConfig: AppConfig
    ) {}

    /**
     * Checks in every set frequency the version of frontend application
     */
    public init() {
        if (!this.appConfig.production) {
            return;
        }

        this.frequency = this.frequency > 0 ? this.frequency : this.defaultFrequency;

        this.appConfig.debug && console.log('version check start', 'frequency', this.frequency);

        setInterval(this.checkVersion.bind(this), this.frequency);
        this.checkVersion();
    }

    /**
     * Will do the call and check if the hash has changed or not
     */
    private checkVersion() {
        // timestamp these requests to invalidate caches
        this.appConfig.debug && console.log('Checking version!');

        this.http.get<VersionCheck>(this.versionCheckUrl + '?t=' + new Date().getTime()).subscribe({
            next: (response) => {
                if (!response) {
                    return;
                }

                let firstLoad = this.firstLoad;

                const hash = response.hash;
                const hashChanged = this.hasHashChanged(this.currentHash, hash);

                this.currentHash = hash;
                if (this.firstLoad) {
                    this.firstLoad = false;
                }

                if (!hashChanged) {
                    return;
                }

                this.appConfig.debug && console.log('Version changed!');

                if (firstLoad) {
                    location.reload();
                    return;
                }

                const snackBarRef = this.snackBar.open('There is a newer version!', 'Update', {
                    duration: 0
                });

                snackBarRef.afterDismissed().subscribe(() => {
                    location.reload();
                });
            },
            error: (err: any) => {
                console.error('Could not get version', err);
            }
        });
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash: string, newHash: string): boolean {
        if (!currentHash || currentHash === 'dev') {
            return false;
        }

        return currentHash !== newHash;
    }
}
